import { AuthorizedContent } from '@frontegg/react';
import { List } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonWithoutBackgroundStyled } from '../../../theme/style';
import RolesType from '../../../utils/enum/RolesType';
import AddDashboardModal from '../../Modals/AddDashboardModal/addDashboardModal';
import surveyListElement from '../constants';
import { ListIconStyled, ListItemStyled, ListItemTextStyled } from '../styled';
import { SurveyProps } from '../surveyInterface';

interface SurveyCardProps {
    survey: SurveyProps
}

const SurveyCard: FC<SurveyCardProps> = ({ survey }) => {
    const { t } = useTranslation(['survey', 'dashboard']);
    const navigate = useNavigate();
    const [openAddModal, setOpenAddModal] = useState<boolean>(false);
    const handleClickAddDashboard = () => {
        setOpenAddModal(true);
    };
    const handleClickRedirect = (key : string) => {
        let redirectUrl = '';
        const dashboardUrl = `/survey/${survey.surveyid}/dashboard/${survey.defaultDashboardId}`;
        const hotAlertUrl = `/survey/${survey.surveyid}/hot-alerts/`;
        if (key === 'dashboardCount') {
            redirectUrl = dashboardUrl;
        } else if (key === 'hot_alert') {
            redirectUrl = hotAlertUrl;
        } else {
            redirectUrl = '';
        }
        navigate(redirectUrl);
    };

    return (
        <List sx={{ paddingBottom: '0' }}>
            {surveyListElement(survey).map((element) => (
                <AuthorizedContent requiredRoles={(element.key === 'hot_alert' || element.key === 'share') ? [RolesType.ADMIN, RolesType.ADMIN_MOAI] : undefined}>
                    <ListItemStyled key={element.key}>
                        <ListIconStyled hasHoverProp={(element.key === 'dashboardCount' && survey.dashboardCount > 0) || (element.key === 'hot_alert' && survey.hotAlertCount)} onClick={() => handleClickRedirect(element.key)}>
                            {element.icon}
                        </ListIconStyled>
                        <ListItemTextStyled primary={element.title} />
                    </ListItemStyled>
                </AuthorizedContent>
            ))}
            {(survey.dashboardCount === 0) && (
                <ButtonWithoutBackgroundStyled onClick={handleClickAddDashboard} sx={{ marginTop: '10px' }} disableRipple>
                    {t('dashboard:createNewDashboard')}
                </ButtonWithoutBackgroundStyled>
            )}
            <AddDashboardModal openModal={openAddModal} setOpenModal={setOpenAddModal} surveyId={String(survey.surveyid)} />
        </List>
    );
};

export default SurveyCard;
