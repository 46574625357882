import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AuthorizedContent } from '@frontegg/react';
import React from 'react';
import RolesType from '../../../utils/enum/RolesType';
import { Indicator } from '../dashboardInterfaces';
import { DragAndDropIconStyled } from '../styled';
import IndicatorCard from './indicatorCard';

interface SortableIndicatorCardProps extends React.ComponentProps<typeof IndicatorCard> {
    indicator: Indicator;
    isDraggable?: boolean;
    isDragging?: boolean;
}

const SortableIndicatorCard: React.FC<SortableIndicatorCardProps> = (props) => {
    const { indicator, isDragging, isDraggable } = props;
    const { attributes, listeners, setNodeRef, transform } = useSortable({ id: indicator.id });

    const style = {
        opacity: 1,
        background: 'transparent',
        transform: CSS.Translate.toString(transform),
        zIndex: isDragging ? 100 : 0,
        position: 'relative' as const,
    };

    return (
        <div ref={setNodeRef} className='relative w-full' style={style}>
            {isDraggable && (<AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}><DragAndDropIconStyled {...attributes} {...listeners} /></AuthorizedContent>)}
            <IndicatorCard {...props} />
        </div>
    );
};

export default SortableIndicatorCard;
